const Controller = require('../../controller');

class GoogleSignInController extends Controller {
	constructor(window) {
		super(window);
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 * @param {DOMEvent} event On load event
	 */
	onLoad() {
		this.trackAction('google-sign-in-landing');
	}
}

module.exports = GoogleSignInController;
