const BillingCity = require('@financial-times/n-conversion-forms/utils/billing-city');
const FormElement = require('@financial-times/n-conversion-forms/utils/form-element');
const Controller = require('../../controller');
const Submit = require('@financial-times/n-conversion-forms/utils/submit');
const {
	getBaseControllerProperties,
	initialiseOnLoad,
	updatePaymentTerm,
	updatePaymentType,
	submitPayment,
} = require('../../common/payment');
const { withEmailVerification } = require('../../common/shared');

class PaymentController extends Controller {
	constructor(window) {
		super(window, { useBrowserValidation: true });

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		const baseProps = getBaseControllerProperties(this.window, this.document, this.CONFIG);
		Object.assign(this, baseProps);
		this.billingCity = new BillingCity(this.document);
		this.fulfilmentOption = this.document.querySelector('#fulfilmentOption')?.value;

		if (this.flags.get('enableZuoraPaymentLinkOnBuyFlow')) {
			this.zuoraPaymentLinkPanel = new FormElement(this.document, '#zuoraPaymentLinkPanel');
			this.zuoraPaymentLinkPanel.hide();
			this.zuoraPaymentLinkSubmitButton = new Submit(this.zuoraPaymentLinkPanel.$el);
		}
	}

	async onLoad(event) {
		super.onLoad(event);

		this.trackAction('payment-landing');

		await initialiseOnLoad(this);
	}

	async onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}

		await submitPayment(event, this);
	}

	/**
	 * React to form changes and update the payment form
	 * @throws If no payment gateway config is returned.
	 */
	async updatePaymentType(event, isInit) {
		await updatePaymentType(this, isInit);
	}

	async updatePaymentTerm(event, isInit) {
		await updatePaymentTerm(this, isInit);
	}
}

module.exports = PaymentController;
