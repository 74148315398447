import readyState from 'ready-state';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import { nMessagingClient } from '@financial-times/n-messaging-client/main-client';
import * as nTracking from '@financial-times/n-tracking';

readyState.dom.then(() => {
	const flags = pageKitFlags.init();
	const appContext = pageKitAppContext.init();

	pageKitLayout.init();

	if (flags.get('oTracking')) {
		nTracking.init({
			appContext: appContext.getAll(),
			// `window.FT.offer` is defined in `components/shared/config.jsx`
			pageViewContext: {
				offerType: getOfferType(window.FT.offer),
			},
		});
	}

	// do not initialise the CMP if the page is being rendered within an iFrame as
	// the banner may block content and not be dismissed when the banner is dismissed
	// on the parent page.
	(async () => {
		if (flags.get('adsDisableInternalCMP') && window.self === window.top) {
			await import(/* webpackIgnore: true */ 'https://consent-notice.ft.com/cmp.js');
		} else {
			nMessagingClient.init();
		}
	})();

	// Find and initalise the controller
	const Controller = getController();
	new Controller(window);
});

/**
 * Try to find a custom controller from the HTML
 * @returns {function} Customer or Base controller
 */
function getController() {
	// Find the custom controller name
	const page = document.querySelector('div[data-controller]');
	if (page) {
		const controllerName = page.dataset['controller'];
		const controllerRouteName = page.dataset['controllerRoute'];
		if (controllerName) {
			try {
				return require(`./controllers/${controllerRouteName}/${controllerName}.js`);
			} catch (error) {
				// Fail silently and run base controller
			}
		}
	}

	// If all else fails use the base controller
	return require('./controller');
}

/**
 * Returns the type of the offer
 * @param {Object} offer Offer object
 * @returns {string|undefined} Offer type: 'digital', 'digital-edition', 'bundle' or 'print'
 */
function getOfferType(offer) {
	if (!offer) {
		return;
	}

	if (offer.isDigital) {
		return 'digital';
	}

	if (offer.isDigitalEdition) {
		return 'digital-edition';
	}

	if (offer.isPrintOrBundle) {
		if (offer.isPrint) {
			return 'print';
		}
		return 'bundle';
	}
}
