const LOADER_MESSAGES = {
	paymentMethodConfig: {
		title: 'Payment method loading',
		content: `<p>This may take a few seconds.</p>
			<p>Please do not leave or refresh the page.</p>`,
	},
	paymentSubmission: {
		title: 'Good news!',
		content: `<p><strong>We are creating your subscription.</strong></p>
			<p>This may take a few minutes.</p>
			<p>Please do not leave or refresh the page.</p>`,
	},
};

const CAN_COUNTRY_CODE = 'CAN';
const GBR_COUNTRY_CODE = 'GBR';
const IND_COUNTRY_CODE = 'IND';
const USA_COUNTRY_CODE = 'USA';
const US_ELECTION_COUNTDOWN_NEWSLETTER_ID = '65b8fec772badb00166eafc6';

module.exports = {
	CAN_COUNTRY_CODE,
	GBR_COUNTRY_CODE,
	IND_COUNTRY_CODE,
	LOADER_MESSAGES,
	USA_COUNTRY_CODE,
	US_ELECTION_COUNTDOWN_NEWSLETTER_ID,
};
