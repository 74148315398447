const Controller = require('../../controller');

class RegisteredController extends Controller {
	constructor(window) {
		super(window);
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 */
	onLoad() {
		super.onLoad();
		this.trackAction('message', { message: 'Error attaching FT Edit licence' });
	}
}

module.exports = RegisteredController;
