const Controller = require('../../controller');

class AccessController extends Controller {
	constructor(window) {
		super(window, {
			mutePromptBeforeLeaving: true,
			// Prevent the default submit from Controller
			preventSubmit: true,
		});

		this.trackingContext.withEmailVerification = this.flags.get('emailVerificationOnSignupForms');

		// Set event listeners to both submits
		const emailSignUpButton = document.getElementById('email-sign-up-button');
		emailSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'sign-up-with-email');
		});

		const googleSignUpButton = document.getElementById('google-sign-up-button');
		googleSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'google-sign-up-button');
		});

		const appleSignUpButton = document.getElementById('apple-sign-up-button');
		appleSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'apple-sign-up-button');
		});
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('access');
	}

	// OnSubmit click, validate and set the submitActionType
	onClick(event, submitActionType) {
		event.preventDefault();
		this.trackAction(submitActionType);

		const isValid = super.validate(event);

		if (!isValid) {
			return false;
		}

		this.$form.submitActionType.value = submitActionType;
		this.$form.submit();
	}
}

module.exports = AccessController;
