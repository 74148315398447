const BillingCountry = require('@financial-times/n-conversion-forms/utils/billing-country');
const Password = require('@financial-times/n-conversion-forms/utils/password');
const Email = require('@financial-times/n-conversion-forms/utils/email');
const Country = require('@financial-times/n-conversion-forms/utils/country');
const DeliveryPostcode = require('@financial-times/n-conversion-forms/utils/delivery-postcode');
const Messages = require('../../helpers/messages');
const Controller = require('../../controller');
const { withEmailVerification } = require('../../common/shared');
const { setupCaptcha } = require('../../helpers/third-party-security');
const { trackBillingCountry } = require('../../common/payment');

class AccountController extends Controller {
	constructor(window) {
		super(window, {
			// Prevent the default submit in Controller and o-forms so we can control
			// where to fire it witch captcha
			preventSubmit: true,
		});

		// Select fields from the form
		this.passwordField = this.document.querySelector('.ncf #password');
		this.emailField = this.document.querySelector('.ncf #email');

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		// Show/Hide Password
		if (this.passwordField) {
			new Password(this.document);
		}

		// Email field
		if (this.emailField) {
			const email = new Email(this.document);

			email.registerEmailExistsCheck(
				'/buy/api/email-check',
				() => {
					this.messages.show(Messages.USER_EXISTS);
				},
				() => {
					this.messages.hide(Messages.USER_EXISTS);
				}
			);
		}

		if (this.CONFIG.offer.isPrintOrBundle) {
			this.deliveryPostcode = new DeliveryPostcode(this.document);
		}

		if (this.CONFIG.offer.isDigital) {
			this.billingCountry = new BillingCountry(this.document);
			this.billingCountry.onChange(() => trackBillingCountry(this, 'cta', 'input'));
			this.billingCountry.$billingCountry.addEventListener('click', () =>
				trackBillingCountry(this, 'cta', 'focus')
			);
		} else {
			// If the country changes update tracking context
			this.country = new Country(this.document);
			this.country.onChange(() => {
				const country = this.country.getSelected();
				this.trackingContext.country = country;
				// If country changes, update postcode label
				if (this.CONFIG.offer.isPrintOrBundle) {
					this.deliveryPostcode.changePostcodeReferenceForCountry = country;
				}
			});
			this.validation.addCustomValidation({
				errorMessage: 'Unfortunately, this offer is not available in your country.',
				field: this.country.$country,
				validator: () => {
					const selected = this.country.getSelected();
					return this.CONFIG.offer && this.CONFIG.offer.availableCountries.indexOf(selected) !== -1;
				},
			});
		}

		// Event triggered by Origami Forms after validation of the form
		this.$form.addEventListener('oForms.submit', (event) => this.onFormValidationCompleted(event));

		// Triggered by captcha callback  in `client/helpers/third-party-security.js`
		this.$form.addEventListener('captcha.submit', (event) => this.onCaptchaSubmit(event));
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');

		if (this.flags.get('reCaptchaAccountStepEnable')) {
			setupCaptcha(this.window);
		}

		this.trackEmailVerificationConfirmed();
	}

	/**
	 * Calls trackAction with the email verification method if any
	 */
	trackEmailVerificationConfirmed() {
		const verificationFlow = new URLSearchParams(this.window.location.search).get('verificationFlow');
		if (verificationFlow === 'email-code') {
			this.trackAction('verification-code-confirmed');
		} else if (verificationFlow === 'email-link') {
			this.trackAction('verification-link-confirmed');
		}
	}

	onFormValidationCompleted(event) {
		this.trackAction('registration-submit');

		if (event.detail.valid) {
			// Populate subX properties on the form hidden inputs
			if (this.flags.get('enableSubX')) {
				event.detail.instance.form.subXIdentifier.value = window.SubxClientEvents?.identifier;
				event.detail.instance.form.subXSessionId.value = window.SubxClientEvents?.sessionId;
			}

			if (this.flags.get('reCaptchaAccountStepEnable')) {
				// This will execute the captcha callback in `client/helpers/third-party-security.js`
				this.window.hcaptcha.execute();
			} else {
				// Submit the form
				event.detail.instance.form.submit(event);
				this.trackAction('registration-confirmed');
			}
		}
	}

	// After captcha succeeds, track the action and submit the form
	onCaptchaSubmit(event) {
		this.trackAction('registration-confirmed');
		this.$form.submit(event);
	}
}

module.exports = AccountController;
