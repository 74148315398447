const { withEmailVerification } = require('../../common/shared');
const Controller = require('../../controller');

class ConfirmationController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);
	}

	/**
	 * Calls trackAction with action name when landing on the form.
	 * @param {DOMEvent} event On load event
	 */
	trackConversionOptimizely() {
		/*
			optimizely conversionDetails are added to the
			view in server/controllers/offer/confirmation.js
			once a user has successfully created a subscription
		*/
		if (this.window) {
			this.window.optimizely = this.window.optimizely || [];
			this.window.optimizely.push({
				type: 'user',
				attributes: {
					...this.window.FT.optimizelyAdditionalDetails?.conversionDetails,
				},
			});
		}
	}
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('preferences-confirmation');
		this.trackConversionOptimizely();
	}
}

module.exports = ConfirmationController;
