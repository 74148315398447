const Controller = require('../../controller');
const YearOfBirth = require('@financial-times/n-conversion-forms/utils/year-of-birth');
const {
	isYearOfBirthValid,
	getYearOfBirthErrorMessage,
} = require('../../../common/helpers/year-of-birth');

class PreferencesController extends Controller {
	constructor(window) {
		super(window);
		this.extendedValidationMessage = true;

		if (this.flags?.get('buy-offer-year-of-birth')) {
			this.yearOfBirth = new YearOfBirth(this.document);

			this.validation.addCustomValidation({
				errorMessage: () => getYearOfBirthErrorMessage(this.yearOfBirth.getYearOfBirth()),
				field: this.yearOfBirth.$yearOfBirth,
				validator: () => isYearOfBirthValid(this.yearOfBirth.getYearOfBirth()),
			});

			this.yearOfBirth.handleYearOfBirthBlur(() => {
				this.validation.checkCustomValidation();
			});
		}
	}

	/**
	 * Provide consent information to the tracking context before landing and sets tracking action name
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('registration-confirmed');
		this.trackAction('preferences-landing');
	}

	/**
	 * Update consent information to the tracking context before submit and sets action name
	 * @param {DOMEvent} event On submit event
	 */
	onSubmit(event) {
		this.trackAction('preferences-submit');

		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}
		this.trackAction('preferences-confirmation');
	}
}

module.exports = PreferencesController;
