/**
 * Determine the type of error for the year of birth field
 * @param {number} yearOfBirth year of birth
 * @returns {object} error object
 */
function determineYearOfBirthError(yearOfBirth) {
	const currentYear = new Date().getFullYear();
	const regexPattern = /^\d{4}$/;
	if (!yearOfBirth) {
		return {
			isError: true,
			errorMessage: 'Please fill out this field',
		};
	} else if (!regexPattern.test(yearOfBirth.toString())) {
		return {
			isError: true,
			errorMessage: 'Please input a four-digit year of birth, e.g. 1999',
		};
	} else if (yearOfBirth < 1900 || yearOfBirth > currentYear) {
		return {
			isError: true,
			errorMessage: 'Please enter a valid year of birth',
		};
	} else if (currentYear - yearOfBirth < 16) {
		return {
			isError: true,
			errorMessage: 'You must be at least 16 years old to have an FT account',
		};
	} else {
		return {
			isError: false,
		};
	}
}

/**
 * Determine if the year of birth is valid
 * @param {number} yearOfBirth year of birth
 * @returns {boolean} true if the year of birth is valid, false otherwise
 */
function isYearOfBirthValid(yearOfBirth) {
	return !determineYearOfBirthError(yearOfBirth).isError;
}

/**
 * Get the error message if the year of birth is invalid
 * @param {number} yearOfBirth year of birth
 * @returns {string} error message describing the error of the year of birth field, null if the year of birth is valid
 */
function getYearOfBirthErrorMessage(yearOfBirth) {
	return determineYearOfBirthError(yearOfBirth).errorMessage;
}

module.exports = {
	isYearOfBirthValid,
	getYearOfBirthErrorMessage,
};
