const Email = require('@financial-times/n-conversion-forms/utils/email');
const Messages = require('../../helpers/messages');
const LicenceController = require('./controller');
const { emailDomainAllowedCheck } = require('../../helpers/email-api');
const { fireGoogleEvent, firePixel, setupLinkedIn } = require('../../helpers/third-party-tracking');
const { isStudent } = require('../../../pages/licence/shared/helpers');

class LicenceDetailsController extends LicenceController {
	constructor(window) {
		super(window);

		// Select fields from the form
		this.emailField = this.document.querySelector('.ncf #email');
		this.$csrfToken = this.document.querySelector('.ncf #csrfToken');
		this.$jobTitleField = this.document.querySelector('.ncf #jobTitle');
		this.$graduationDate = this.document.querySelector('.ncf #graduationDateWrapper');

		// Email field
		if (this.emailField) {
			this.email = new Email(this.document);

			this.email.registerEmailExistsCheck(
				'/buy/api/email-check',
				() => {
					this.messages.hide(Messages.EMAIL_NOT_ALLOWED);
					this.messages.show(Messages.USER_EXISTS);
				},
				() => {
					this.messages.hide(Messages.USER_EXISTS);
				}
			);

			this.emailField.addEventListener('change', (event) => {
				return emailDomainAllowedCheck({
					email: event.target.value,
					csrfToken: this.$csrfToken.value,
					callback: (isAllowed) => {
						if (isAllowed) {
							this.messages.hide(Messages.EMAIL_NOT_ALLOWED);
						} else if (!this.messages.isShowing(Messages.USER_EXISTS)) {
							this.messages.show(Messages.EMAIL_NOT_ALLOWED);
						}
					},
				});
			});

			this.$jobTitleField.addEventListener('change', (event) =>
				this.toggleGraduationDate(event.target.value)
			);
		}
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');
		setupLinkedIn(this.window, '21561');
		this.toggleGraduationDate(this.$jobTitleField.value);
	}

	toggleGraduationDate = (jobTitle) => {
		if (!this.$graduationDate) return;

		const hiddenClassName = 'o-normalise-visually-hidden';

		if (isStudent(jobTitle)) {
			this.$graduationDate.classList.remove(hiddenClassName);
		} else {
			this.$graduationDate.classList.add(hiddenClassName);
		}
	};

	onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}

		this.trackAction('submit');
		fireGoogleEvent(this.window, 'form', 'submit', '14-day-guest-pass');
		firePixel(
			this.window,
			'https://dc.ads.linkedin.com/collect/?pid=21561&conversionId=785481&fmt=gif'
		);
	}
}

module.exports = LicenceDetailsController;
