const Controller = require('../../controller');

class DebugMessageController extends Controller {
	constructor(window) {
		super(window);

		const toggleAnchors = [...document.getElementsByClassName('toggler-link')];

		toggleAnchors.forEach((anchor) => {
			const key = anchor.getAttribute('data-key');
			const toggleFunction = toggleWhiteBackground.bind(null, key);
			anchor.addEventListener('click', toggleFunction);
		});
	}
}

/**
 * Toggle on and off the white background used on forms
 * @param {string} key
 * @returns {boolean} false
 */
function toggleWhiteBackground(key) {
	const wrapperClass = 'ncf__wrapper';
	const wrapper = document.getElementById(key + '-wrapper');

	if (wrapper.classList.contains(wrapperClass)) {
		wrapper.classList.remove(wrapperClass);
	} else {
		wrapper.classList.add(wrapperClass);
	}
	return false;
}

module.exports = DebugMessageController;
