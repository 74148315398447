const Controller = require('../../controller');
const Messages = require('../../helpers/messages');
const { withEmailVerification } = require('../../common/shared');

const LINK_CHAT =
	'<a href="https://help.ft.com/help/contact-us/" class="o-message__actions__secondary o-message__content-highlight" data-trackable="chat">chat</a>';
const LINK_CALL =
	'<a href="https://help.ft.com/help/contact-us" class="o-message__actions__secondary o-message__content-highlight" data-trackable="call">call</a>';
const CUSTOMER_SERVICE_MESSAGE = `If you need help please ${LINK_CHAT} to or ${LINK_CALL} our customer care team.`;

class CodeController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);
		this.extendedValidationMessage = true;
	}

	resendEmailCall(verificationKey) {
		// try to resend email
		const offer = this.CONFIG.offer;
		const url = `/buy/api/${offer.id}/resend-verification-email`;
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ verificationKey }),
		};
		const resendButton = document.querySelector('#resendVerification');
		resendButton.disabled = true;
		this.window
			.fetch(url, options)
			.then((res) => {
				if (!res.ok) {
					this.messages.show(Messages.ERROR_SENDING_EMAIL);
				}
			})
			.catch(() => {
				this.messages.show(Messages.EMAIL_VERIFICATION_EMAIL_ERROR);
			});

		// After code is resent, then replace countdonw message to do not confuse the user
		const resendMessage = document.querySelector('#resendMessage');
		resendMessage.innerHTML = `Verification code has been resent. Please wait a moment for the email to arrive.</br>${CUSTOMER_SERVICE_MESSAGE}`;
	}

	initResendCountDown(timeToEnableResendButton) {
		// Set the date we're counting down to (now plus the given milliseconds).
		const now = new Date().getTime();
		const countDownDate = new Date(now + timeToEnableResendButton);

		// Update the count down every 1 second
		const countDown = setInterval(function () {
			// Get today's date and time
			const now = new Date();

			// Find the time remaining between now and the count down date
			const timeRemaining = countDownDate - now;

			// Time calculations for minutes and seconds
			const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

			const resendCountDown = document.querySelector('#resendCountDown');

			// Output the result in an element with id="demo"
			resendCountDown.innerHTML = minutes + 'm ' + seconds + 's';

			// If the count down is over, write some text
			if (timeRemaining < 0) {
				resendCountDown.innerHTML = '0m 0s';
				clearInterval(countDown);
			}
		}, 1000);
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('verification-code-landing');

		const timeToEnableResendButton = parseInt(this.CONFIG.timeToEnableResendButton) || 2 * 60 * 1000;

		this.initResendCountDown(timeToEnableResendButton);

		setTimeout(() => {
			resendButton.disabled = false;
		}, timeToEnableResendButton);

		const resendButton = document.querySelector('#resendVerification');
		resendButton?.addEventListener('click', (e) => {
			if (Boolean(resendButton.disabled)) {
				e.preventDefault();
				return false;
			}
			// prevent default action and try to resend email
			e.preventDefault();
			const verificationKey = document.getElementById('verificationKey')?.value;
			this.resendEmailCall(verificationKey);
		});
	}

	onSubmit(event) {
		this.trackAction('verification-code-submit');

		if (!super.onSubmit(event)) {
			return false;
		}
	}
}

module.exports = CodeController;
