/**
 * @typedef {Event} CaptchaSubmitEvent - An event emitted when the user submits a successful response in captcha
 * @property {Object} detail - The event detail.
 * @property {Object} detail.token  - The h-captcha-response token
 */

/**
 * Inject the captcha script
 * @param {Window} window Browser window object
 */
function setupCaptcha(window) {
	const script = window.document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = 'https://js.hcaptcha.com/1/api.js';

	window.document.head.appendChild(script);

	/*
		onCaptchaSubmit is called by hCaptcha automatically as a callback
		The name is specified on the attrribute `data-callback` in the invisible catpcha
		'div' eg,`pages/offer/account.jsx`
		Docs: https://docs.hcaptcha.com/invisible/#programmatically-invoke-the-challenge
	*/

	window.onCaptchaSubmit = (token) => {
		const signupForm = document.querySelector('form.ncf');
		const submitButton = document.querySelector('#submitButton');

		/**
		 * @type {CaptchaSubmitEvent}
		 */
		const captchaSubmitEvent = new CustomEvent('captcha.submit', {
			detail: {
				token,
			},
			cancelable: true,
			bubbles: true,
			submitter: submitButton,
		});

		signupForm.dispatchEvent(captchaSubmitEvent);
	};
}

module.exports = {
	setupCaptcha,
};
