const { extendedMeterEligible } = require('../../../server/helpers/google-extended-meter');
const Controller = require('../../controller');

class ConsentController extends Controller {
	constructor(window) {
		super(window);
	}

	/**
	 * Calls trackAction with action name when landing on the form.
	 */
	onLoad() {
		let additionalTrackingContext = {};

		const urlParams = new URLSearchParams(this.window.location.search);
		if (extendedMeterEligible(this.flags?.flags, Object.fromEntries(urlParams))) {
			additionalTrackingContext = {
				type: 'google-showcase',
				country: this.CONFIG.country,
				url: this.document.URL,
			};
		}

		this.trackAction('registration-confirmed', additionalTrackingContext);
	}
}

module.exports = ConsentController;
