const Controller = require('../../controller');

class RegistrationConfirmedController extends Controller {
	constructor(window) {
		super(window);
		this.extendedValidationMessage = true;
	}

	/**
	 * Provide consent information to the tracking context before landing and sets tracking action name
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');
		/*
		 * For Social logins (Apple and Google), FT account is created at this point but confirmation event was not fired.
		 * User may leave the journey at this point and we will not count that user even if account has been created.
		 * Adding this registration-confirmed event for more accurate tracking.
		 */
		this.trackAction('registration-confirmed');
		this.trackAction('social-confirmed');
	}

	/**
	 * Update consent information to the tracking context before submit and sets action name
	 * @param {DOMEvent} event On submit event
	 */
	onSubmit(event) {
		this.trackAction('consent-submit');

		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}
		this.trackAction('consent-confirmed');
		this.trackingContext.consent = this.consentForm.values;
	}
}

module.exports = RegistrationConfirmedController;
