const {
	PROFESSIONAL_SUBSCRIPTION_CHECK_COMPONENT_TRACKING_IDS,
} = require('../../../server/config/professional-subscription-check');
const Controller = require('../../controller');

class ConfirmationController extends Controller {
	constructor(window) {
		super(window);
	}

	/**
	 * Calls trackAction with action name when landing on the form.
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('registration-confirmed');
	}

	customTrackingEvents() {
		super.customTrackingEvents();

		// track professional subscription check submit
		const checkButton = this.document.querySelector(
			'[data-trackable="professional-subscription-check-banner-request"]'
		);
		if (checkButton) {
			checkButton.addEventListener('click', () => {
				this.trackAction('professional-check-banner-submit', {
					type: 'subscription-finder',
				});
			});
		}

		// track professional subscription check view
		const professionalSubscriptionCheckBanner = this.document.querySelector(
			'[data-trackable="professional-subscription-check-banner"]'
		);
		if (professionalSubscriptionCheckBanner) {
			const componentViewTrackingContext = {
				type: 'subscription-finder',
				component: {
					id: PROFESSIONAL_SUBSCRIPTION_CHECK_COMPONENT_TRACKING_IDS.banner,
					name: 'professional-subscription-check-banner',
					type: 'section',
				},
			};
			this.trackAction('view', componentViewTrackingContext, 'component');
		}
	}
}

module.exports = ConfirmationController;
