const Controller = require('../../controller');
class AccessController extends Controller {
	constructor(window) {
		super(window, {
			mutePromptBeforeLeaving: true,
			preventSubmit: true,
		});

		const emailSignUpButton = document.getElementById('register-continue-free');
		emailSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'register-continue-free');
		});

		const googleSignUpButton = document.getElementById('google-sign-up-button');
		googleSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'google-sign-up-button');
		});

		const appleSignUpButton = document.getElementById('apple-sign-up-button');
		appleSignUpButton?.addEventListener('click', (event) => {
			this.onClick(event, 'apple-sign-up-button');
		});
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('access');
	}

	// When the user clicks on the "Signup with Google" button,
	// we want to validate the event
	onClick(event, submitActionType) {
		event.preventDefault();
		this.trackAction(submitActionType);
		const isValid = super.validate(event);

		if (!isValid) {
			return false;
		}

		this.$form.submitActionType.value = submitActionType;
		this.$form.submit();
	}
}

module.exports = AccessController;
