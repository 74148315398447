const Controller = require('../../controller');
const EventNotifier = require('@financial-times/n-conversion-forms/utils/event-notifier');

class LicenceController extends Controller {
	constructor(window) {
		super(window);

		this.notifier = EventNotifier.init(window.document.querySelector('.ncf__wrapper'));
	}

	onLoad(event) {
		super.onLoad(event);

		// This is needed for the embedded B2B Forms (used on the B2B barrier)
		this.notifier.notify();
	}
}

module.exports = LicenceController;
