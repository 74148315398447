/**
 * Append an image tag onto the element with the given image source
 * @param {DOMElement} element Element to fire pixel on normally the body
 * @param {string} src Absolute URL of the pixel image
 */
function firePixel(window, src) {
	const pixel = window.document.createElement('img');
	pixel.src = src;
	pixel.width = 1;
	pixel.height = 1;
	pixel.style.display = 'none';

	window.document.body.appendChild(pixel);
}

/**
 * Try to use the global gtag method to fire an event
 * @param {Window} window Browser window object
 * @param {string} category Event category
 * @param {string} action Event action
 * @param {string} label Event label
 * @param {string} property Google Property to fire the event against
 */
function fireGoogleEvent(window, category, action, label, property = false) {
	// Return early if Google Tagmanager not on the page
	if (!window.gtag) return;

	const data = { event_category: category, event_label: label };

	// If property has been specified add to config and data
	if (property) {
		data.send_to = property;
		window.gtag('config', property);
	}

	// Fire the event
	window.gtag('event', action, data);
}

/**
 * Inject the LinkedIn tracking script
 * @param {Window} window Browser window object
 * @param {string} partnerId LinkedIn partner id
 */
function setupLinkedIn(window, partnerId) {
	window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
	window._linkedin_data_partner_ids.push(partnerId);

	const script = window.document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

	window.document.head.appendChild(script);
}

module.exports = {
	firePixel,
	fireGoogleEvent,
	setupLinkedIn,
};
