class FetchPaymentTerm extends Error {
	/**
	 * Enhance message with data
	 * @param {string} message Message of the error
	 * @param {*} [data] Supporting information
	 */
	constructor(message, data) {
		super(message);
		this.data = data;
	}

	/**
	 * Return the error name with the error message
	 * @returns {string}
	 */
	toString() {
		const dataString = JSON.stringify(this.data, null, 2);
		return `${this.constructor.name}: ${this.message}\n\n${dataString}`;
	}
}

module.exports = {
	FetchPaymentTerm,
};
