const YearOfBirth = require('@financial-times/n-conversion-forms/utils/year-of-birth');
const { UpdateConsentOnSave } = require('@financial-times/n-profile-ui/dist/client/main');
const SubscriptionConfirmationWithPaymentLink = require('@financial-times/n-conversion-forms/utils/subscription-confirmation-with-payment-link');
const Controller = require('../../controller');
const { withEmailVerification } = require('../../common/shared');
const {
	isYearOfBirthValid,
	getYearOfBirthErrorMessage,
} = require('../../../common/helpers/year-of-birth');

class PreferencesController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		this.consentForm = new UpdateConsentOnSave({
			selector: '.ncf__fieldset[name="customise-experience"]',
		});

		if (this.flags?.get('buy-offer-year-of-birth')) {
			this.yearOfBirth = new YearOfBirth(this.document);

			this.validation.addCustomValidation({
				errorMessage: () => getYearOfBirthErrorMessage(this.yearOfBirth.getYearOfBirth()),
				field: this.yearOfBirth.$yearOfBirth,
				validator: () => isYearOfBirthValid(this.yearOfBirth.getYearOfBirth()),
			});

			this.yearOfBirth.handleYearOfBirthBlur(() => {
				this.validation.checkCustomValidation();
			});
		}

		const subscriptionConfirmationWithPaymentLinkPanel = this.document.querySelector(
			SubscriptionConfirmationWithPaymentLink.CONTAINER_QUERY_SELECTOR
		);
		if (subscriptionConfirmationWithPaymentLinkPanel) {
			const utilPaymentLinkPanel = new SubscriptionConfirmationWithPaymentLink(this.document);
			utilPaymentLinkPanel.handleCopyPaymentLink();
		}
	}

	/**
	 * Provide consent information to the tracking context before landing and sets tracking action name
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		this.trackAction('subscription-confirmation');
		this.trackAction('preferences-landing');
		super.onLoad(event);
		this.trackingContext.consent = this.consentForm.values;
	}

	/**
	 * Update consent information to the tracking context before submit and sets action name
	 * @param {DOMEvent} event On submit event
	 */
	onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}
		this.trackingContext.consent = this.consentForm.values;
		this.trackAction('preferences-submit');
	}
}

module.exports = PreferencesController;
