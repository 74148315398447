const Controller = require('../../controller');
const { getUrlQueryParam, getInArticleSignupFormType } = require('../../common/shared');
const { getConceptIdForTrackingContext } = require('../../common/shared');

class InArticleSignUpConfirmationController extends Controller {
	constructor(window) {
		super(window);

		const newsletterMappingDiv = this.document.querySelector('.ncf__div-newsletter-mapping');
		const { newsletter, licence, duration } = newsletterMappingDiv.dataset || {};

		this.newsletter = newsletter;

		const isEmbedded = Boolean(getUrlQueryParam(this.window, 'embed'));
		const formType = getInArticleSignupFormType(newsletter, isEmbedded);

		const urlParams = new URLSearchParams(this.trackingContext?.referrer);
		const conceptId = urlParams.get('concept-id');
		const newsletterId = urlParams.get('newsletter-id');

		Object.assign(this.trackingContext, {
			type: newsletter ? newsletter : 'in-article',
			formType,
			accessDuration: duration,
			licenceId: licence,
			conceptId: getConceptIdForTrackingContext(newsletterId, conceptId),
		});
	}

	/**
	 * This loads the LiveChat client
	 */
	loadLiveChat() {
		return false;
	}

	/**
	 * Calls trackAction with action name when landing on the form.
	 */
	onLoad() {
		const action = this.newsletter ? 'prospect-confirmed' : 'registration-confirmed';
		this.trackAction(action);
	}
}

module.exports = InArticleSignUpConfirmationController;
