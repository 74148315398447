const ApplePay = require('@financial-times/n-conversion-forms/utils/apple-pay');
const PaymentType = require('@financial-times/n-conversion-forms/utils/payment-type');
const PaymentTerm = require('@financial-times/n-conversion-forms/utils/payment-term');
const BillingCountry = require('@financial-times/n-conversion-forms/utils/billing-country');
const Messages = require('./messages');
const fetchres = require('fetchres');

class PaymentModule {
	constructor(window, config) {
		this.window = window;
		this.document = this.window.document;
		this.config = config;

		this.$form = this.document.querySelector('form.ncf');
		this.$paymentMethodId = this.document.querySelector('#paymentMethodId');
		this.$paymentGateway = this.document.querySelector('#paymentGateway');
		this.paymentType = new PaymentType(this.document);
		this.paymentTerm = new PaymentTerm(this.document);
		this.billingCountry = new BillingCountry(this.document);
	}

	submitForm() {
		this.$form.submit();
	}

	setPaymentMethodId(paymentMethodId) {
		this.$paymentMethodId.value = paymentMethodId;
	}

	setPaymentGateway(paymentGateway) {
		this.$paymentGateway.value = paymentGateway;
	}

	hasPaymentMethodId() {
		return Boolean(this.$paymentMethodId.value);
	}

	/**
	 * Find the selected payment term in the pages config data
	 * @returns {Object}
	 * @throws When the payment term is not found
	 */
	getPaymentTerm(selectedTerm) {
		const paymentTerms = this.config.paymentTerms || {};
		const paymentTerm = paymentTerms[selectedTerm];

		if (!paymentTerm) {
			throw new Error('Payment term could not be found');
		}

		return paymentTerm;
	}

	/**
	 * Retrieve the Zuora gateway config
	 * @param {string} paymentType Type of payment
	 * @param {string} paymentTerm Payment term (aka billingFrequency) P1M,P1Y,etc
	 * @param {string} billingCountry ISO 3 character country code
	 * @param {string} formType Type of form (single || multi)
	 * @param {number} amount The base amount for the selected payment term (not the formatted price)
	 * @param {string} zuora3DsOptIn value of the zuora3DsOptIn flag
	 * @returns {Promise<Object>}
	 */
	async fetchZuoraGatewayConfig(
		paymentType,
		paymentTerm,
		billingCountry,
		amount,
		zuora3DsOptIn = null
	) {
		const queryParams = new URLSearchParams({
			paymentType,
			country: billingCountry,
			amount,
			zuora3DsOptIn,
		});

		// Add paymentTerm to query params only if it's not empty
		if (paymentTerm) {
			queryParams.append('paymentTerm', paymentTerm);
		}

		const apiUrl = new URL(`/buy/api/${this.config.offer.id}/zuora-config`, window.location.origin);
		apiUrl.search = queryParams.toString();

		const response = await this.window.fetch(apiUrl, { method: 'POST' });

		// Process an error response
		if (!response.ok) {
			const data = await response.json();
			throw new Error(data.message);
		}

		// Collect the configuration
		const config = await fetchres.json(response);
		if (!config) {
			throw new Error(Messages.PAYMENT_COUNTRY_CHANGE_ERROR);
		}

		// Update the form with the Payment Gateway
		this.setPaymentGateway(config.paymentGateway);

		return config;
	}

	/**
	 * Try to initialise an Apple Pay instance
	 * @returns {boolean} True if the user can make an ApplePay payment
	 */
	async loadApplePay() {
		const methods = this.config.isTest ? ApplePay.TEST_PAYMENT_METHODS : ApplePay.PAYMENT_METHODS;
		try {
			this.applePay = new ApplePay(this.window, methods);
			return await this.applePay.canMakePayment();
		} catch (error) {
			return false;
		}
	}

	/**
	 * Show the payment interface and await the user to complete the payment.
	 * On success create a string of the token JSON and display success.
	 * On failure tell the interface to display failure and throw.
	 * @param {string} selectedTerm
	 * @throws {DOMException} On abort by user or implementation security issues
	 * @throws {Error} On payment failure
	 */
	async submitApplePay(selectedTerm) {
		const offer = this.config.offer;
		const paymentTerm = this.getPaymentTerm(selectedTerm);
		const amount = paymentTerm.trialAmount || paymentTerm.amount;

		const paymentDetails = ApplePay.getPaymentDetails(amount, paymentTerm.currency, offer.name);

		const response = await this.applePay.show(paymentDetails);

		if (response.details && response.details.token) {
			response.complete('success');
			const token = JSON.stringify(response.details.token);
			this.setPaymentMethodId(token);
			this.submitForm();
		} else {
			response.complete('fail');
			throw new Error('Payment failed');
		}
	}

	/**
	 * retrieve the config needed for the zuora iframe
	 * @returns {Object}
	 * @throws if fails to get the config from membership or does not pass validation of params
	 */
	async getZuoraConfig(zuora3DsOptIn) {
		const paymentType = this.paymentType.getSelected();
		const paymentTerm = this.paymentTerm.getSelected();
		const billingCountry = this.paymentTerm.getCountryCode();
		const authorizationAmount = Number(this.paymentTerm.getBaseAmount());
		const config = await this.fetchZuoraGatewayConfig(
			paymentType,
			paymentTerm,
			billingCountry,
			authorizationAmount,
			zuora3DsOptIn
		);

		return config;
	}
}

module.exports = PaymentModule;
