/**
 * This helper checks if a Flag called enableGoogleExtendedMeter is enabled and the incoming URL has the four query required parameters which Google Entitlement needs:
 * 		gaa_at & gaa_ts && gaa_n && gaa_sig
 *
 * @param flags FT system flags
 * @param query the request query object
 * @returns true if the user is eligible for an extended meter entitlement, otherwise false
 */
const extendedMeterEligible = (flags = {}, query = {}) => {
	if (!flags.enableGoogleExtendedMeter) {
		return false;
	}

	if (!(query.gaa_at && query.gaa_ts && query.gaa_n && query.gaa_sig)) {
		return false;
	}

	return true;
};

module.exports = { extendedMeterEligible };
