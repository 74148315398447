const Controller = require('../../controller');
const Messages = require('../../helpers/messages');
const { initialiseEmailPasswordProperties, withEmailVerification } = require('../../common/shared');

class EmailController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		initialiseEmailPasswordProperties(
			this,
			this.onFoundHandler.bind(this),
			this.onNotFoundHandler.bind(this)
		);
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('verification-email-landing');
	}

	onSubmit(event) {
		this.trackAction('verification-email-submit');

		if (!super.onSubmit(event)) {
			return false;
		}
		this.trackAction('verification-email-confirmed');
	}

	onNotFoundHandler() {
		this.messages.hide(Messages.USER_EXISTS);
	}
	onFoundHandler() {
		this.messages.show(Messages.USER_EXISTS);
	}
}

module.exports = EmailController;
