const SubmitButton = require('@financial-times/n-conversion-forms/utils/submit');
const {
	LiveChatPopupProjectFelix: LiveChatPopup,
} = require('@financial-times/n-live-chat/dist/browser');
const nTracking = require('@financial-times/n-tracking');
const Tracking = require('@financial-times/n-conversion-forms/utils/tracking');
const Validation = require('@financial-times/n-conversion-forms/utils/validation');
const pageKitFlags = require('@financial-times/dotcom-ui-flags/dist/node/client/index');
const Messages = require('./helpers/messages');
const PaymentType = require('@financial-times/n-conversion-forms/utils/payment-type');

class Controller {
	constructor(
		window,
		{ useBrowserValidation, preventValidation, mutePromptBeforeLeaving, preventSubmit = false } = {}
	) {
		// Window object helpers
		this.window = window;
		this.document = this.window.document;
		this.body = this.document.body;
		this.CONFIG = this.window.FT || {};
		this.$form = this.document.querySelector('form.ncf');
		this.category = this.CONFIG.trackingCategory || {};
		this.preventValidation = preventValidation;
		this.flags = pageKitFlags.init();

		// Initialise validation on the document
		if (!preventValidation) {
			try {
				this.validation = new Validation({
					mutePromptBeforeLeaving,
					formsOptions: {
						errorSummaryMessage: 'Please correct the errors below and try again.',
						preventSubmit,
						useBrowserValidation,
					},
				});
				this.validation.init();
			} catch (error) {
				// Fails if there is no form on the page
			}
		}

		// track which optimizely tests a user is exposed to
		if (this.flags && this.flags.get('optimizely')) {
			this.trackOptimizelyTests();
		}

		// Setup tracking
		this.tracking = new Tracking(this.window, this.body);
		this.trackingContext = this.CONFIG.trackingContext || {};
		this.trackingContext.url = this.window.location.href;

		// Special case for Zuora payment link
		if (this.trackingContext.paymentType === PaymentType.ZUORAPAYMENTLINK) {
			this.trackingContext.paymentType = PaymentType.CREDITCARD;
			this.trackingContext.isDeferredPayment = true;
		}

		this.customTrackingEvents();

		// Setup messages including tracking handler on show
		this.messages = new Messages(this.document);
		this.messages.onShow(this.trackMessage.bind(this));

		// Add event listeners
		if (this.$form && !preventSubmit) {
			this.submitButton = new SubmitButton(this.document);
			this.$form.addEventListener('submit', this.onSubmit.bind(this));
		}

		if (this.document.readyState === 'complete') {
			this.onLoad();
		} else {
			this.document.addEventListener('readystatechange', () => {
				if (this.document.readyState === 'complete') {
					this.onLoad();
				}
			});
		}
	}

	/**
	 * This is the first listener and sets up tracking.
	 * On overriding this function make sure you call it
	 * `super.onLoad(event);`
	 */
	onLoad() {
		// Track the messages that were rendered on load
		this.messages.currentlyShown().forEach(this.trackMessage.bind(this));
		this.loadLiveChat();
	}

	/**
	 * This loads the LiveChat client
	 */
	loadLiveChat() {
		// Setup the live chat component
		try {
			const callbacks = {
				// agent online, chat available: popup opens
				online: () => this.trackAction('livechat-triggerOpen'),
				// agents offline, chat unavailable
				offline: () => this.trackAction('livechat-triggerClosedNoOperators'),
				// user clicked button to start chat
				open: () => this.trackAction('livechat-startChat'),
				// user clicked button to dismiss popup
				dismiss: () => this.trackAction('livechat-triggerClose'),
			};

			// a hacky way to to enable chatterBox based on the existence of a DOM element
			// @TODO change to a Non-DOM data source
			const chatterBox = Boolean(document.getElementById('enable-livechat-chatterbox'));

			const options = {
				// wait 10 seconds before initialising component
				displayDelay: 10000,

				// enable chatterBox (FT internal live chat vs SF chat)
				chatterBox,
			};

			// If liveChatProjectFelix flag is ON, the latest LiveChat version (called Project Felix) is initialised
			if (this.flags.get('liveChatProjectFelix')) {
				const liveChat = new LiveChatPopup();
				liveChat.init(callbacks, options);
			}
		} catch (error) {
			this.trackAction('livechat-error', { message: error.message });
		}
	}

	trackOptimizelyTests() {
		const activeTests = window?.FT?.optimizelyAdditionalDetails?.spoorTracking;

		if (activeTests) {
			activeTests.forEach((test) => {
				nTracking.broadcast('oTracking.event', {
					category: 'optimizely',
					action: 'experiment',
					...test,
				});
			});
		}
	}

	/**
	 * Called when the submit event is fired on the window.
	 * This is the first listener and sets up tracking.
	 * On overriding this function make sure you call it
	 * `super.onSubmit(event);`
	 * @param {Event} event DOM Event
	 */
	onSubmit(event) {
		// Stop right here if the button is already disabled.
		if (this.submitButton?.isDisabled()) {
			event.preventDefault();
			return false;
		}
		this.submitButton?.disable();

		if (!this.preventValidation) {
			return this.validate(event);
		}

		return true;
	}

	/**
	 * Validates the form
	 *
	 * @param {Event} event DOM Event
	 * @returns {boolean} isValid
	 */
	validate(event) {
		// If the invalid on submit show messages and focus first invalid field
		this.validation.checkFormValidity();
		if (!this.validation.formValid) {
			this.submitButton?.enable();

			// Track validation error messages
			this.trackMessage(Messages.VALIDATION_ERROR);

			// Validation runs on blur so untouched fields are not validated
			// Run validation to validate all fields again and show messages
			this.validation.validateForm(event);

			// Focus on the first invalid element
			const elements = this.validation.getInvalidEls();
			if (elements && elements[0]) {
				elements[0].focus();
			}

			// Don't track invalid submits and stop form submission.
			event.preventDefault();
			return false;
		}
		return true;
	}

	/**
	 * Track an action happening on the site
	 * @param {string} action What has happened
	 * @param {Object} context Extra information about the event
	 * @param {string} category The category for tracking, if deviating from default 'signup'
	 */
	trackAction(action, context = {}, category = null) {
		const fullContext = Object.assign({}, this.trackingContext, context);
		this.tracking.dispatch(category || this.category, action, fullContext);
	}

	/**
	 * Tracks a message being shown to the user
	 * @param {string} message Message shown
	 */
	trackMessage(message) {
		// this.trackAction('message', { message });
		const context = { message };

		// If showing validation message
		if (message === Messages.VALIDATION_ERROR && this.validation) {
			context.invalidFields = this.validation.getInvalidEls().map((element) => element.name);

			const invalidFieldsString = this.extendedValidationMessage
				? ` ${context.invalidFields.join(':')}`
				: '';
			context.message = `${context.message}${invalidFieldsString}`;
		}

		this.trackAction('message', context);
	}

	/**
	 * Setup custom tracking events
	 */
	customTrackingEvents() {
		const changeProductButton = this.document.querySelector('[data-trackable="change"]');

		if (changeProductButton) {
			changeProductButton.addEventListener('click', () => {
				this.trackAction('change-product');
			});
		}
	}
}

module.exports = Controller;
