const { UpdateConsentOnSave } = require('@financial-times/n-profile-ui/dist/client/main');

const Email = require('@financial-times/n-conversion-forms/utils/email');
const Messages = require('../../helpers/messages');
const {
	getUrlQueryParam,
	getInArticleSignupFormType,
	showComponent,
	hideComponent,
} = require('../../common/shared');

const Controller = require('../../controller');
const { getConceptIdForTrackingContext } = require('../../common/shared');

class InArticleSignUpDetailsController extends Controller {
	constructor(window) {
		super(window);

		const isMobile = /Mobi/.test(window.navigator.userAgent);

		if (isMobile) {
			this.mobileSignUpButton = this.document.querySelector('#mobileSignUpButton');
			this.mobileSubmitDiv = this.document.querySelector('.ncf__div-mobile-submit');
		}

		const emailButtonDivField = this.document.querySelector('.ncf__div-newsletter-mapping');
		const { newsletter, licence, duration } = emailButtonDivField.dataset || {};

		// Select fields from the form.querySelector('.ncf #email');
		this.consentFields = this.document.querySelector('.ncf__div-consent-fields');
		this.emailField = this.document.querySelector('.ncf #email');
		const emailUtil = new Email(this.document);

		const userExistsMessage = newsletter
			? Messages.USER_EXISTS_NEWSLETTER_IFRAME_IN_ARTICLE
			: Messages.USER_EXISTS_IFRAME_IN_ARTICLE;

		emailUtil.registerEmailExistsCheck(
			'/buy/api/email-check',
			() => {
				this.messages.show(userExistsMessage);
				this.submitButton.disable();
				this.resizeIframe();
			},
			() => {
				this.messages.hide(userExistsMessage);
				this.submitButton.enable();
				this.resizeIframe();
			}
		);

		this.emailField.addEventListener('focus', () => {
			if (isMobile) {
				// hiding consents and confirm button on mobile devices, even in case user wants to modify the email
				hideComponent(this.consentFields);
				hideComponent(this.mobileSubmitDiv);
				// show signup button on mobile devices
				showComponent(this.mobileSignUpButton);
			} else {
				showComponent(this.consentFields);
			}
			this.messages.hide(Messages.GENERIC_ERROR_ON_IFRAME);
			this.messages.hide(Messages.VALIDATION_ERROR);
			this.messages.hide(userExistsMessage);
			this.resizeIframe();
		});

		if (isMobile && this.mobileSignUpButton) {
			this.mobileSignUpButton.addEventListener('click', () => {
				this.messages.hide(Messages.VALIDATION_ERROR);
				this.validation.checkFormValidity();

				if (this.validation.formValid) {
					hideComponent(this.mobileSignUpButton);
					showComponent(this.consentFields);
					showComponent(this.mobileSubmitDiv);
				} else {
					this.messages.show(Messages.VALIDATION_ERROR);
				}

				this.resizeIframe();
			});
		}

		this.consentForm = new UpdateConsentOnSave({
			selector: '.ncf__fieldset[name="in-article-sign-up"]',
		});

		const isEmbedded = Boolean(getUrlQueryParam(this.window, 'embed'));
		const formType = getInArticleSignupFormType(newsletter, isEmbedded);

		const conceptId = getUrlQueryParam(this.window, 'concept-id');
		const newsletterId = getUrlQueryParam(this.window, 'newsletter-id');

		const trackingContext = {
			type: newsletter ? newsletter : 'in-article',
			formType,
			accessDuration: duration,
			licenceId: licence,
			conceptId: getConceptIdForTrackingContext(newsletterId, conceptId),
		};
		// means that next-subscribe in-article-sign-up is inside an iframe loaded by next-article,
		// so we need to get the o-tracking root_id from the `trackingRootId` url param to make tracking consistent between
		// page (next-article) and iframe (next-subscribe)
		if (this.inIframe()) {
			const urlParams = new URLSearchParams(window.location.search);
			const rootId = urlParams.get('trackingRootId');
			if (rootId) {
				trackingContext.root_id = rootId;
			}
		}
		Object.assign(this.trackingContext, trackingContext);
	}

	inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
	resizeIframe() {
		// first scroll the window to the top and then fire the resize event
		this.window.scrollTo(0, 0);
		const event = new CustomEvent('ft-in-article-sign-up-resize-iframe');
		this.window.parent.document.dispatchEvent(event);
	}

	loadLiveChat() {
		return false;
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');
	}

	/**
	 * Calls trackAction with action name on form submission
	 * @param {DOMEvent} event On load event
	 */
	onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			this.resizeIframe();
			return false;
		}

		this.trackAction('submit');
	}
}

module.exports = InArticleSignUpDetailsController;
