const LicenceController = require('./controller');
const { firePixel } = require('../../helpers/third-party-tracking');

class LicenceConfirmationController extends LicenceController {
	constructor(window) {
		super(window);
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction(
			this.trackingContext.formType === 'next.partnerships'
				? 'partnership-confirmation'
				: 'confirmation'
		);
		firePixel(
			this.window,
			'https://dc.ads.linkedin.com/collect/?pid=21561&conversionId=246377&fmt=gif'
		);
		firePixel(this.window, 'https://www.facebook.com/tr?id=165183117461128&ev=PageView&noscript=1');
	}
}

module.exports = LicenceConfirmationController;
