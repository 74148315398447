const fetchres = require('fetchres');

/**
 * Validates a user's email domain is allowed
 *
 * @param {string} object.email email address to check against
 * @param {string} object.csrfToken CSRF token from form
 * @param {function} object.callback Success callback with either true | false as its value
 */
function emailDomainAllowedCheck({ email, csrfToken, callback }) {
	const config = window.FT || {};
	const queryString = window.location.search;

	if (email) {
		return fetch(
			`/join/licence/api/email-domain-check/${config.licence.redeemableTokenId + queryString}`,
			{
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'CSRF-Token': csrfToken,
				},
				body: JSON.stringify({
					email,
				}),
			}
		)
			.then(fetchres.json)
			.then((r) => callback(r === true ? true : false))
			.catch(() => callback(false));
	} else {
		callback(false);
	}
}

module.exports = {
	emailDomainAllowedCheck,
};
