const Controller = require('../../controller');
const Messages = require('../../helpers/messages');

const { initialiseEmailPasswordProperties } = require('../../common/shared');
class AccountController extends Controller {
	constructor(window) {
		super(window);

		this.extendedValidationMessage = true;

		initialiseEmailPasswordProperties(
			this,
			this.onFoundHandler.bind(this),
			this.onNotFoundHandler.bind(this)
		);
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');
	}

	onSubmit(event) {
		this.trackAction('registration-submit');

		if (!super.onSubmit(event)) {
			return false;
		}
	}

	onNotFoundHandler() {
		this.messages.hide(Messages.USER_EXISTS);
	}
	onFoundHandler() {
		this.messages.show(Messages.USER_EXISTS);
	}
}

module.exports = AccountController;
