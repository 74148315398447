const LicenceController = require('./controller');
const { fireGoogleEvent, firePixel, setupLinkedIn } = require('../../helpers/third-party-tracking');

class LicenceRegisteredController extends LicenceController {
	constructor(window) {
		super(window);
	}

	onLoad(event) {
		super.onLoad(event);
		this.trackAction('landing');
		setupLinkedIn(this.window, '21561');
	}

	onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}
		this.trackAction('submit');
		fireGoogleEvent(this.window, 'form', 'submit', '14-day-guest-pass');
		firePixel(
			this.window,
			'https://dc.ads.linkedin.com/collect/?pid=21561&conversionId=785481&fmt=gif'
		);
	}
}

module.exports = LicenceRegisteredController;
