const Controller = require('../../controller');

class ProfessionalSubscriptionCheckController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.type = 'subscription-finder';
		this.$requestButton = window.document.querySelector('#request-to-join-button');
		this.$requestButton.addEventListener('click', this.trackProfessionalCheckSubmit.bind(this));
	}

	/**
	 * Provide consent information to the tracking context before landing and sets tracking action name
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);

		this.trackAction('registration-confirmed');
		this.trackAction('professional-check-landing');
	}

	trackProfessionalCheckSubmit() {
		this.trackAction('professional-check-submit');
	}
}

module.exports = ProfessionalSubscriptionCheckController;
