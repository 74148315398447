const zuoraErrorMessages = [
	{
		messageMatcher: /expiry date/,
		messageKey: 'paymentSubmitErrorExpirationDate',
	},
	{
		messageMatcher: /card number/,
		messageKey: 'paymentSubmitErrorCardNumberValidation',
	},
	{ messageMatcher: /cvv/, messageKey: 'paymentSubmitErrorCvvValidation' },
	{
		messageMatcher: /card issuer/,
		messageKey: 'paymentSubmitErrorCardIssuerValidation',
	},
	{
		messageMatcher: /3d/,
		codeMatcher: /(threeds2|20150|20153|20154)/,
		messageKey: 'paymentSubmitErrorNeed3Ds',
	},
];

/**
 * Simple class for hiding and showing messages
 */
class Messages {
	constructor(document) {
		this.$document = document;
		this.showListeners = [];
	}

	/**
	 * Get an message element
	 * @param {string} Message name
	 * @throws {Error} If element does not exist
	 * @returns {Element}
	 */
	get(name) {
		const message = this.$document.querySelector(`[data-message-name=${name}]`);
		if (!message) {
			throw new Error(`Message ${name} not found.`);
		}
		return message;
	}

	/**
	 * Setup a listener for when we show messages
	 * @param {function} callback Run when showing messages
	 */
	onShow(callback) {
		this.showListeners.push(callback);
	}

	/**
	 * Find the messages that are currently visible
	 * @returns {Array} Message names being shown
	 */
	currentlyShown() {
		const messages = [];
		const found = this.$document.querySelectorAll('[data-message-name]');

		if (found) {
			found.forEach((message) => {
				// Test if the message is visible by checking it's offsetParent
				if (message.offsetParent) {
					messages.push(message.dataset.messageName);
				}
			});
		}

		return messages;
	}

	/**
	 * Return true if the given message is currently showing
	 * @param {string} Message name
	 * @returns {boolean}
	 */
	isShowing(name) {
		return this.currentlyShown().includes(name);
	}

	/**
	 * Show the message
	 * @param {string} Message name
	 */
	show(name) {
		const error = this.get(name);
		error.classList.remove('ncf__hidden');
		this.showListeners.forEach((listener) => listener(name));
	}

	/**
	 * Focus the message
	 * @param {string} Message name
	 */
	focus(name) {
		const message = this.get(name);
		message.tabIndex = 0;
		message.focus();
	}

	/**
	 * Return Mapped error message on payment transaction otherwise returns false
	 * @param {Object} event the event with the error message that need to be mapped
	 * @returns {string}
	 */
	mapZuoraErrorMessageDetail(event) {
		for (let message of zuoraErrorMessages) {
			if (
				message.messageMatcher?.test(event.message?.toLowerCase()) ||
				message.codeMatcher?.test(event.code?.toLowerCase())
			) {
				return message.messageKey;
			}
		}
		return false;
	}

	/**
	 * Remove actions
	 * @param {string} Message name
	 */
	removeActions(name) {
		const message = this.get(name);
		message.querySelectorAll('.o-message__actions').forEach((el) => {
			el.parentNode.removeChild(el);
		});
	}

	/**
	 * Hide the message
	 * @param {string} Message name
	 */
	hide(name) {
		if (name) {
			try {
				const error = this.get(name);
				error.classList.add('ncf__hidden');
			} catch (error) {
				// We can safely swallow this since any error thrown here means the error doesn't exist (and thus is "hidden")
			}
		}
	}

	static get VALIDATION_ERROR() {
		return 'validationError';
	}

	static get USER_EXISTS() {
		return 'userExistsNotice';
	}

	static get USER_EXISTS_IFRAME() {
		return 'userExistsNoticeIframe';
	}

	static get USER_EXISTS_IFRAME_IN_ARTICLE() {
		return 'userExistsNoticeIframeInArticle';
	}

	static get USER_EXISTS_NEWSLETTER_IFRAME_IN_ARTICLE() {
		return 'userExistsNoticeNewsletterIframeInArticle';
	}

	static get EMAIL_NOT_ALLOWED() {
		return 'emailNotAllowed';
	}

	static get PAYMENT_APPLE_PAY_ERROR() {
		return 'paymentApplePayError';
	}

	static get PAYMENT_ERROR() {
		return 'paymentSubmitError';
	}

	static get PAYMENT_ERROR_EXPIRATION_DATE() {
		return 'paymentSubmitErrorExpirationDate';
	}

	static get PAYMENT_ERROR_CARD_NUMBER_VALIDATION() {
		return 'paymentSubmitErrorCardNumberValidation';
	}

	static get PAYMENT_ERROR_CVV_VALIDATION() {
		return 'paymentSubmitErrorCvvValidation';
	}

	static get PAYMENT_ERROR_CARD_ISSUER_VALIDATION() {
		return 'paymentSubmitErrorCardIssuerValidation';
	}

	static get PAYMENT_ERROR_NEED_3DS() {
		return 'paymentSubmitErrorNeed3Ds';
	}

	static get PAYMENT_COUNTRY_CHANGE_ERROR() {
		return 'paymentCountryChangeError';
	}

	static get PAYMENT_TERM_UPDATE_ERROR() {
		return 'paymentTermUpdateError';
	}

	static get GENERIC_ERROR_ON_IFRAME() {
		return 'detailsSubmitErrorIframe';
	}

	static get EMAIL_VERIFICATION_EMAIL_ERROR() {
		return 'emailVerificationEmailError';
	}

	static get PAYMENT_EMPTY_USER_ID_ERROR() {
		return 'paymentGatewayEmptyUserId';
	}

	static get ZUORA_PAYMENT_LINK_ERROR() {
		return 'zuoraPaymentLinkSubmitError';
	}
}

module.exports = Messages;
