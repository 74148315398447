const BillingCountry = require('@financial-times/n-conversion-forms/utils/billing-country');
const Country = require('@financial-times/n-conversion-forms/utils/country');
const DeliveryPostcode = require('@financial-times/n-conversion-forms/utils/delivery-postcode');
const Controller = require('../../controller');
const { withEmailVerification } = require('../../common/shared');
const { trackBillingCountry } = require('../../common/payment');

class RegisteredController extends Controller {
	constructor(window) {
		super(window, {
			// Prevent the default submit in Controller and o-forms so we can add
			// values to the Subx hidden inputs
			preventSubmit: true,
		});

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		if (this.CONFIG.offer.isPrintOrBundle) {
			this.deliveryPostcode = new DeliveryPostcode(this.document);
		}

		if (this.CONFIG.offer.isDigital) {
			this.billingCountry = new BillingCountry(this.document);
			if (this.billingCountry) {
				this.billingCountry.onChange(() => trackBillingCountry(this, 'cta', 'input'));
				this.billingCountry.$billingCountry.addEventListener('click', () =>
					trackBillingCountry(this, 'cta', 'focus')
				);
			}
		} else {
			// If the country changes update tracking context
			this.country = new Country(this.document);

			if (this.country) {
				this.country.onChange(() => {
					const country = this.country.getSelected();
					this.trackingContext.country = country;
					// If country changes, update postcode label
					if (this.CONFIG.offer.isPrintOrBundle) {
						this.deliveryPostcode.changePostcodeReferenceForCountry = country;
					}
				});
			}

			this.validation.addCustomValidation({
				errorMessage: 'Unfortunately, this offer is not available in your country.',
				field: this.country.$country,
				validator: () => {
					const selected = this.country.getSelected();
					return this.CONFIG.offer && this.CONFIG.offer.availableCountries.indexOf(selected) !== -1;
				},
			});
		}

		// Event triggered by Origami Forms after validation of the form
		this.$form.addEventListener('oForms.submit', (event) => this.onFormValidationCompleted(event));
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 */
	onLoad() {
		super.onLoad();
		this.trackAction('landing');

		const params = new URLSearchParams(this.document.location.search);
		const verificationFlow = params.get('verificationFlow');

		if (['apple', 'google'].includes(verificationFlow)) {
			/*
			 * For Social logins (Apple and Google), FT account is created at this point but confirmation event was not fired.
			 * User may leave the journey at this point and we will not count that user even if account has been created.
			 * Adding this registration-confirmed event for more accurate tracking.
			 */
			this.trackAction('registration-confirmed');
			this.trackAction('social-confirmed');
		}
	}

	/**
	 * Populates SubX hidden inputs and submits the form
	 * @param {DOMEvent} event On load event
	 */
	onFormValidationCompleted(event) {
		if (event.detail.valid) {
			// Populate SubX values on the form hidden inputs
			if (this.flags.get('enableSubX')) {
				event.detail.instance.form.subXIdentifier.value = window.SubxClientEvents?.identifier;
				event.detail.instance.form.subXSessionId.value = window.SubxClientEvents?.sessionId;
			}

			// Submit the form
			event.detail.instance.form.submit(event);
		}
	}
}

module.exports = RegisteredController;
