const Email = require('@financial-times/n-conversion-forms/utils/email');
const Password = require('@financial-times/n-conversion-forms/utils/password');
const { US_ELECTION_COUNTDOWN_NEWSLETTER_ID } = require('../constants');

/**
 * Initialises email and password properties and behaviours
 * @param {ClientController} controller controller to update
 * @param {function} foundHandler function to call if email already exists as a FT profile
 * @param {function} notFoundHandler function to call if email does not exist as a FT profile
 */
function initialiseEmailPasswordProperties(controller, foundHandler, notFoundHandler) {
	controller.passwordField = controller.document.querySelector('.ncf #password');
	controller.emailField = controller.document.querySelector('.ncf #email');

	// Show (plain text) or Hide (mask) Password
	if (controller.passwordField) {
		new Password(controller.document);
	}

	// Email field
	if (controller.emailField) {
		const email = new Email(controller.document);

		email.registerEmailExistsCheck('/buy/api/email-check', foundHandler, notFoundHandler);
	}
}

/**
 * Extract a query parameter from the window url
 * @param {Window} window browser window object
 * @param {string} queryParam the query parameter to look for
 * @returns {string} the value of the query parameter if found, undefined otherwise
 */
function getUrlQueryParam(window, queryParam) {
	return new URLSearchParams(window.location.search).get(queryParam);
}

/**
 * Get the tracking context type for for the in-article signup form
 * @param {string} newsletter the name of the newsletter
 * @param {boolean} isEmbedded whether the in-article form is embedded or not
 * @returns {string} the tracking context type
 */
function getInArticleSignupFormType(newsletter, isEmbedded) {
	const formType = newsletter ? 'next.newsletter' : 'next.register';
	return isEmbedded ? `${formType}.sticky-banner` : formType;
}

/**
 * Show component by removing 'ncf__hidden' style class
 * @param component The DOM component to be shown
 */
function showComponent(component) {
	component?.classList?.remove('ncf__hidden');
}

/**
 * Hide component by adding 'ncf__hidden' style class
 * @param component The DOM component to be hidden
 */
function hideComponent(component) {
	component?.classList?.add('ncf__hidden');
}

function withEmailVerification(flags) {
	if (!flags) {
		return false;
	}

	const emailVerificationOnSignupForms = flags.get('emailVerificationOnSignupForms');
	const verificationFlow = new URLSearchParams(window?.location.search).get('verificationFlow');
	const isUserOnEmailVerificationJourney = ['email-code', 'email-link'].includes(verificationFlow);

	return Boolean(emailVerificationOnSignupForms && isUserOnEmailVerificationJourney);
}

function getConceptIdForTrackingContext(newsletterId, conceptId) {
	/* conceptId is added in the tracking context to track in-article-sign-up new edition.
		This will be part of signup:landing and signup:submit actions. It will help to differenciate
		between personalised and generic behaviour for in-article-sign-up.
		It has been decided to use same conceptId to differenciate US election newsletter as well.
		Cases will be as follows -
		1. If US election newsletter - conceptId = "USElectionConcept"
		2. If concept-id present in URL params - conceptId = conceptId in the URL
		3. Else - conceptId = unpopularConcept
	*/
	return newsletterId === US_ELECTION_COUNTDOWN_NEWSLETTER_ID
		? 'USElectionConcept'
		: conceptId ?? 'unpopularConcept';
}

module.exports = {
	initialiseEmailPasswordProperties,
	getUrlQueryParam,
	getInArticleSignupFormType,
	showComponent,
	hideComponent,
	withEmailVerification,
	getConceptIdForTrackingContext,
};
