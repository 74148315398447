const { DateTime } = require('luxon');

const DeliveryOption = require('@financial-times/n-conversion-forms/utils/delivery-option');
const DeliveryStartDate = require('@financial-times/n-conversion-forms/utils/delivery-start-date');
const DeliveryAddressType = require('@financial-times/n-conversion-forms/utils/delivery-address-type');
const DeliveryPOBox = require('@financial-times/n-conversion-forms/utils/delivery-po-box');
const DeliveryAddress = require('@financial-times/n-conversion-forms/utils/delivery-address');
const CompanyName = require('@financial-times/n-conversion-forms/utils/company-name');
const DeliveryInstructions = require('@financial-times/n-conversion-forms/utils/delivery-instructions');
const Controller = require('../../controller');
const { withEmailVerification } = require('../../common/shared');

class DeliveryController extends Controller {
	constructor(window) {
		super(window);

		this.trackingContext.withEmailVerification = withEmailVerification(this.flags);

		this.extendedValidationMessage = true;

		this.$deliveryPostcode = this.document.querySelector('.js-delivery-postcode');

		this.startDateUtil = new DeliveryStartDate(this.document);
		this.deliveryOption = new DeliveryOption(this.document);
		try {
			this.deliveryPOBox = new DeliveryPOBox(this.document);
		} catch (e) {
			// DeliveryPOBox not present on page
		}
		try {
			this.deliveryCompany = new CompanyName(this.document);
		} catch (e) {
			// CompanyName not present on page
		}
		this.deliveryAddress = new DeliveryAddress(this.document);
		this.deliveryInstructions = new DeliveryInstructions(this.document);
		try {
			this.deliveryAddressType = new DeliveryAddressType(this.document);
			// init first components status based on default deliveryAddress selection.
			this.deliveryAddressTypeAction();
			// Components status based on deliveryAddress selected value
			this.deliveryAddressType.onChange(() => {
				this.deliveryAddressTypeAction();
			});
		} catch (e) {
			// DeliveryAddressType not present on page
		}

		this.validation.addCustomValidation({
			errorMessage:
				'There was an error with the selected start date. Please try again, or call customer services on <a href="tel:+442077556248" class="ncf__link ncf__link--external">+44 (0) 207 755 6248</a>.',
			field: this.startDateUtil.$deliveryStartDate,
			validator: this.deliveryStartDateValidator.bind(this),
		});

		// Check the above custom validation when the delivery option changes since that affects the start date.
		this.deliveryOption.handleDeliveryOptionChange(() => {
			this.startDateUtil.$deliveryStartDate.value = DateTime.now().toFormat('yyyy-MM-dd');
			this.validation.checkCustomValidation();

			// Update the delivery information on the tracking context
			this.trackingContext.deliveryOption = this.$form.deliveryOption.value;
		});
	}

	deliveryAddressTypeAction() {
		const selectedValue = this.deliveryAddressType.getSelected();

		switch (selectedValue) {
			case 'pobox':
				this.deliveryAddress.hide();
				this.deliveryAddress.makeOptional();
				this.deliveryPOBox.show();
				this.deliveryPOBox.makeRequired();
				this.deliveryCompany.show();
				this.deliveryCompany.makeOptional();
				this.deliveryInstructions.hide();
				this.updateDeliveryOptionStatus('HD', 'ML');
				break;
			case 'home':
				this.deliveryAddress.show();
				this.deliveryAddress.makeRequired();
				this.deliveryPOBox.hide();
				this.deliveryPOBox.makeOptional();
				this.deliveryCompany.hide();
				this.deliveryCompany.makeOptional();
				this.deliveryInstructions.show();
				this.updateDeliveryOptionStatus('ML', 'HD');
				break;
			case 'company':
				this.deliveryAddress.show();
				this.deliveryAddress.makeRequired();
				this.deliveryPOBox.hide();
				this.deliveryPOBox.makeOptional();
				this.deliveryCompany.show();
				this.deliveryCompany.makeRequired();
				this.deliveryInstructions.show();
				this.updateDeliveryOptionStatus('ML', 'HD');
				break;
			default:
				break;
		}
	}

	updateDeliveryOptionStatus(itemToHide, itemToShow) {
		if (this.deliveryOption.$form.deliveryOption.length > 1) {
			this.deliveryOption.showItem(itemToShow);
			this.deliveryOption.hideItem(itemToHide);
			this.deliveryOption.checkItem(itemToShow);
		}
	}

	deliveryStartDateValidator() {
		return this.startDateUtil.handleDeliveryStartDateChange(
			'/buy/api/delivery-start-date',
			this.getDeliveryStartDateConfig.bind(this)
		);
	}

	getDeliveryStartDateConfig() {
		return {
			country: this.window.FT.country,
			postcode: this.$deliveryPostcode.innerText.trim(),
			productCode: this.window.FT.offer.printProductCode,
			selectedFulfilmentOption: this.$form.deliveryOption.value,
		};
	}

	/**
	 * Calls trackAction with action name when landing on the form
	 * @param {DOMEvent} event On load event
	 */
	onLoad(event) {
		super.onLoad(event);
		this.trackAction('delivery-landing');
	}

	/**
	 * Calls trackAction with action name on form submission
	 * @param {DOMEvent} event On load event
	 */
	onSubmit(event) {
		// If the parent onSubmit validation fails don't continue
		if (!super.onSubmit(event)) {
			return false;
		}

		// Update the delivery information on the tracking context
		this.trackingContext.deliveryOption = this.$form.deliveryOption.value;
		this.trackingContext.deliveryStartDate = this.startDateUtil.$deliveryStartDate.value;

		this.trackAction('delivery-submit');
	}
}

module.exports = DeliveryController;
