const fetchres = require('fetchres');

const BillingCountry = require('@financial-times/n-conversion-forms/utils/billing-country');
const Loader = require('@financial-times/n-conversion-forms/utils/loader');
const PaymentTerm = require('@financial-times/n-conversion-forms/utils/payment-term');
const PaymentType = require('@financial-times/n-conversion-forms/utils/payment-type');
const RegionSelector = require('@financial-times/n-conversion-forms/utils/region-selector');
const Zuora = require('@financial-times/n-conversion-forms/utils/zuora');
const BillingPostcode = require('@financial-times/n-conversion-forms/utils/billing-postcode');

const Messages = require('../helpers/messages');
const PaymentModule = require('../helpers/payment');
const { FetchPaymentTerm } = require('../helpers/errors');

const {
	CAN_COUNTRY_CODE,
	GBR_COUNTRY_CODE,
	IND_COUNTRY_CODE,
	LOADER_MESSAGES,
	USA_COUNTRY_CODE,
} = require('../constants');

const ZUORA_IFRAME_ID = 'z_hppm_iframe';

/**
 * Create initial payement properties
 * @param {window} window browser window object
 * @param {document} document browser document object
 * @param {Object} CONFIG basic config
 * @returns {Object} initialised properties
 */
function getBaseControllerProperties(window, document, CONFIG) {
	return {
		zuora: new Zuora(window),
		paymentType: new PaymentType(document),
		paymentTerm: new PaymentTerm(document),
		billingCountry: new BillingCountry(document),
		loader: new Loader(document.querySelector('.n-layout__row--footer')),
		paymentModule: new PaymentModule(window, CONFIG),
		canadianProvince: new RegionSelector(document, {
			country: CAN_COUNTRY_CODE,
			fieldId: `regionSelector${CAN_COUNTRY_CODE}`,
		}),
		indianState: new RegionSelector(document, {
			country: IND_COUNTRY_CODE,
			fieldId: `regionSelector${IND_COUNTRY_CODE}`,
		}),
		usState: new RegionSelector(document, {
			country: USA_COUNTRY_CODE,
			fieldId: `regionSelector${USA_COUNTRY_CODE}`,
		}),
		billingPostcode: new BillingPostcode(document),
	};
}

/**
 * Set properties required by onLoad
 * @param {ViewController} controller
 * @returns {Promise<void>}
 */
async function initialiseOnLoad(controller) {
	const boundUpdatePaymentTerm = controller.updatePaymentTerm.bind(controller);
	controller.billingCountry.onChange(boundUpdatePaymentTerm);
	controller.paymentTerm.onChange(boundUpdatePaymentTerm);

	const boundUpdatePaymentType = controller.updatePaymentType.bind(controller);
	controller.billingCountry.onChange(boundUpdatePaymentType);
	controller.paymentType.onChange(boundUpdatePaymentType);

	const showApplePay =
		controller.flags &&
		controller.flags.get('showApplePay') &&
		(await controller.paymentModule.loadApplePay());

	if (showApplePay) {
		controller.trackAction('applePay');
		controller.paymentType.show(PaymentType.APPLEPAY);
	} else {
		controller.paymentType.hide(PaymentType.APPLEPAY);
	}

	initZuoraEventHandlers(controller);
	/*
		Important: updatePaymentType and updatePaymentTerm are 2 asynchronous methods
		that run in parallel.
		Be sure that they never modify common parts or there will be a race conditions
		with side effects.
	*/
	// Update the payment form based on the currently selected payment type

	// updatePaymentType is needed, as it also initialises zuora listeners
	updatePaymentType(controller, true);

	// This is not needed on load, the payment terms would already be available rendered server side,
	// leaving it commented as we keep going back and forth on this, this will act as documentation.
	// updatePaymentTerm(controller, true);
}

async function submitDirectDebit(controller, paymentType) {
	try {
		// call to zuora to create credential user profile
		controller.zuora.setAgreement();

		controller.loader.hide();
		const result = await controller.zuora.submit(paymentType);
		if (result) {
			controller.loader.showAndPreventTabbing();
		}
	} catch (error) {
		controller.loader.hide();
		controller.submitButton.enable();
		if (error instanceof Zuora.ZuoraErrorValidation) {
			// Nothing to do here, the validation messages will be shown inside iframe
			controller.trackAction('zuora-validation-error');
		} else if (error instanceof Zuora.ZuoraErrorMandateCancel) {
			// Nothing to do here, the user has just cancelled the mandate confirmation
			controller.trackAction('direct-debit-mandate-cancel');
		} else if (error instanceof Zuora.ZuoraErrorInvalidPaymentType) {
			controller.paymentType.displayError();
		} else {
			// Something untoward has happened throw so we are aware
			throw error;
		}
	}
}

async function submitApplePay(controller, paymentTerm) {
	try {
		await controller.paymentModule.submitApplePay(paymentTerm);
	} catch (error) {
		controller.loader.hide();
		controller.submitButton.enable();
		if (error instanceof DOMException) {
			// @todo This should fail silently but would be useful to keep metrics on `error.name`
		} else {
			controller.messages.show(Messages.PAYMENT_APPLE_PAY_ERROR);
		}
	}
}

async function submitCreditcard(controller, paymentType, enableInteractions) {
	try {
		// call to zuora to create credential user profile
		controller.zuora.setAgreement();

		await controller.zuora.submit(paymentType);
	} catch (error) {
		if (enableInteractions) {
			// restore form elements so that user can take action
			enableUserInteractions();
		}
		controller.submitButton.enable();
		if (error instanceof Zuora.ZuoraErrorValidation) {
			// Nothing to do here, the validation messages will be shown inside iframe
			controller.trackAction('zuora-validation-error');
		} else if (error instanceof Zuora.ZuoraErrorInvalidPaymentType) {
			controller.paymentType.displayError();
			await loadZuoraIframe(controller);
		} else {
			// Something untoward has happened throw so we are aware
			throw error;
		}
	}
}

/**
 * React to the billing country changing by updating the payment terms available
 * @throws If payment terms fail to update
 */
async function updatePaymentTerm(controller, isInit) {
	const billingCountry = controller.billingCountry.getSelected();
	const paymentTerm = controller.paymentTerm.getSelected();
	const offer = controller.CONFIG.offer;
	const fulfilmentOptionQueryParam = controller.fulfilmentOption
		? `&fulfilmentOption=${controller.fulfilmentOption}`
		: '';
	const url = `/buy/api/${offer.id}/payment-terms?country=${billingCountry}${fulfilmentOptionQueryParam}`;
	const options = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};

	if (!isInit) {
		controller.messages.hide(Messages.PAYMENT_TERM_UPDATE_ERROR);
	}

	// Update the postcode label so that it's correctly localised
	controller.billingPostcode.changePostcodeReferenceForCountry = billingCountry;

	// For subsequent tracking events use the new billing country
	// @todo: remove trackingContext.country, kept for backward compatibility
	controller.trackingContext.country = billingCountry;
	controller.trackingContext.billingCountry = billingCountry;
	controller.trackingContext.paymentTerm = paymentTerm;

	// Default form
	controller.paymentType.hide(PaymentType.DIRECTDEBIT);

	// Normalize country related fields
	controller.canadianProvince.hide();
	controller.canadianProvince.disable();
	controller.indianState.hide();
	controller.indianState.disable();
	controller.usState.hide();
	controller.usState.disable();

	controller.billingPostcode.hide();
	controller.billingPostcode.disable();
	if (controller.billingCity) {
		controller.billingCity.hide();
		controller.billingCity.disable();
	}

	// billing country specific form changes
	if (billingCountry === GBR_COUNTRY_CODE) {
		controller.paymentType.show(PaymentType.DIRECTDEBIT);
	} else if (billingCountry === USA_COUNTRY_CODE) {
		controller.usState.show();
		controller.usState.enable();
		controller.billingPostcode.show();
		controller.billingPostcode.enable();
		if (controller.billingCity) {
			controller.billingCity.show();
			controller.billingCity.enable();
		}
	} else if (billingCountry === CAN_COUNTRY_CODE) {
		controller.canadianProvince.show();
		controller.canadianProvince.enable();
		controller.billingPostcode.show();
		controller.billingPostcode.enable();
		if (controller.billingCity) {
			controller.billingCity.show();
			controller.billingCity.enable();
		}
	} else if (billingCountry === IND_COUNTRY_CODE) {
		controller.indianState.show();
		controller.indianState.enable();
	}

	if (controller.CONFIG.offer.isDigitalEdition) {
		if (controller.billingCity) {
			controller.billingCity.show();
			controller.billingCity.enable();
		}
		controller.billingPostcode.show();
		controller.billingPostcode.enable();
	}

	try {
		const response = await controller.window.fetch(url, options);
		const terms = await fetchres.json(response);
		controller.CONFIG.paymentTerms = terms;
		controller.paymentTerm.updateOptions(terms);
		// we need this for 3DS because otherwise we send the wrong authorization amount to
		// the iframe hence when challenged users might see wrong amounts
		const paymentType = controller.paymentType.getSelected();

		// In some rare cases, controller.billingCountry.getSelected() return AFG (first item of the list)
		// instead of the correct value. For a given paymentTerm, we want the price and country to match, therefore
		// setting both of them on the same component.
		controller.paymentTerm.updateCountryCode(billingCountry);

		if (!isInit && paymentType === PaymentType.CREDITCARD) {
			// adding specs for this is a bit of a pain; too hard to mock a real life window...
			// we should add it to WebdrvierIO tests though
			// hot-fixing now, sorry
			controller.loader.showOnElement(
				controller.paymentType.$paymentType,
				LOADER_MESSAGES.paymentMethodConfig
			);

			await loadZuoraIframe(controller);
		}
		controller.loader.hide();
	} catch (error) {
		controller.paymentType.hide(PaymentType.CREDITCARD);
		controller.paymentType.hide(PaymentType.DIRECTDEBIT);
		controller.paymentType.hide(PaymentType.APPLEPAY);
		controller.paymentType.show(PaymentType.PAYPAL);
		controller.paymentType.hidePanel();
		controller.messages.show(Messages.PAYMENT_TERM_UPDATE_ERROR);

		throw new FetchPaymentTerm(`Fetch Payment Term Failed: ${error}`, {
			error,
		});
	}
}

/**
 * React to form changes and update the payment form
 * @throws If no payment gateway config is returned.
 */
async function updatePaymentType(controller, isInit) {
	const paymentType = controller.paymentType.getSelected();

	// Update the trackingContext for future tracking events
	controller.trackingContext.paymentType = paymentType;

	// if this isn't the first time the message is being shown hide the message on payment type change
	// i.e. don't hide it page load after payment has been submitted for the first time, but do hide it on changing payment type
	if (!isInit) {
		controller.messages.hide(Messages.PAYMENT_COUNTRY_CHANGE_ERROR);
		controller.messages.hide(Messages.PAYMENT_ERROR);
		controller.messages.hide(Messages.PAYMENT_APPLE_PAY_ERROR);
		controller.messages.hide(Messages.PAYMENT_EMPTY_USER_ID_ERROR);
		controller.messages.hide(Messages.ZUORA_PAYMENT_LINK_ERROR);
	}

	// Update submit button text
	if (paymentType === PaymentType.CREDITCARD || paymentType === PaymentType.DIRECTDEBIT) {
		controller.submitButton.updateText('Subscribe and Pay');
	}
	if (paymentType === PaymentType.APPLEPAY) {
		controller.submitButton.updateText('Pay with Apple Pay');
	}
	if (paymentType === PaymentType.PAYPAL) {
		controller.submitButton.updateText('Pay with PayPal');
		controller.submitButton.enable();
	}

	// For Zuora payment methods fetch the payment gateway configuration
	if (paymentType === PaymentType.CREDITCARD || paymentType === PaymentType.DIRECTDEBIT) {
		controller.loader.showOnElement(
			controller.paymentType.$paymentType,
			LOADER_MESSAGES.paymentMethodConfig
		);

		await loadZuoraIframe(controller);
	}

	if (controller.flags?.get('enableZuoraPaymentLinkOnBuyFlow')) {
		controller.zuoraPaymentLinkPanel?.hide();
		controller.submitButton.show();

		// For Zuora payment link method fetch the payment gateway configuration
		if (paymentType === PaymentType.ZUORAPAYMENTLINK) {
			if (isInit) {
				// hide the loader initiated by default on page load
				controller.loader.removeFromElement(controller.paymentType.$paymentType);
			}

			await loadZuoraPaymentLinkPanel(controller);
		}
	}
}

async function loadZuoraPaymentLinkPanel(controller) {
	controller.submitButton.hide();
	controller.zuoraPaymentLinkPanel.show();

	controller.loader.showOnElement(
		controller.zuoraPaymentLinkPanel.$el,
		LOADER_MESSAGES.paymentMethodConfig
	);

	try {
		const paymentType = PaymentType.CREDITCARD;
		const paymentTerm = controller.paymentTerm.getSelected();
		const billingCountry = controller.billingCountry.getSelected();
		const authorizationAmount = Number(controller.paymentTerm.getBaseAmount());
		await controller.paymentModule.fetchZuoraGatewayConfig(
			paymentType,
			paymentTerm,
			billingCountry,
			authorizationAmount
		);

		controller.submitButton.enable();
		controller.zuoraPaymentLinkSubmitButton.enable();
	} catch (error) {
		controller.messages.show(Messages.PAYMENT_EMPTY_USER_ID_ERROR);
	}

	controller.loader.removeFromElement(controller.zuoraPaymentLinkPanel.$el);
}

async function loadZuoraIframe(controller) {
	try {
		const zuora3DsOptIn = controller.flags && controller.flags.get('zuora3DsOptIn');
		const config = await controller.paymentModule.getZuoraConfig(zuora3DsOptIn);

		// Show form and update configuration on the window object
		controller.zuora.render({
			params: config,
			renderCallback: () => {
				controller.submitButton.enable();
				if (config.useFakeZuora) {
					// when hacking in a page, the iframe won't resize
					document.getElementById(ZUORA_IFRAME_ID).height = 520;
				}
				controller.zuora.iframe.show();
				controller.loader.removeFromElement(controller.paymentType.$paymentType);
			},
			// invoked on captcha state change
			captchaCallback: (event) => {
				if (event.visible) {
					// hide the loader if the captcha is being displayed
					controller.loader.hide();
				} else if (config.is3DS !== 'true') {
					// show the loader after the captcha is completed and hidden
					// AND the iframe is NOT 3DS enabled - otherwise the loader gets in the way...
					/**
					 * @disabled
					 * The following call has been disabled because of issues
					 * with the reCaptcha tickbox and callbacks affecting the loading spinner
					 * @ticket https://financialtimes.atlassian.net/browse/ACQ-1701
					 */
					// controller.loader.showAndPreventTabbing(LOADER_MESSAGES.paymentSubmission);
				}
			},
		});
	} catch (error) {
		controller.zuora.iframe.hide();
		controller.submitButton.disable();
		controller.loader.removeFromElement(controller.paymentType.$paymentType);
		// for now we keep PAYMENT_COUNTRY_CHANGE_ERROR as default error. TODO: refactoring at client side
		const message = Messages[error.message]
			? Messages[error.message]
			: Messages.PAYMENT_COUNTRY_CHANGE_ERROR;
		controller.messages.show(message);
		await loadZuoraIframe(controller);

		throw error;
	}
}
function initZuoraEventHandlers(controller) {
	/**
	 * listen for event fired when Zuora submission complete
	 * @return {string} paymentMethodId
	 * @todo Move this into the Zuora util
	 */
	controller.document.addEventListener('ft-payment-gateway-result', async (event) => {
		// zuora iframe has returned a response, restore disabled fields
		enableUserInteractions();

		if (event.detail.success) {
			controller.paymentModule.setPaymentMethodId(event.detail.paymentMethodId);

			// we've got the payment method id
			// restore the disabled fields and display the loader while we submit to membership
			controller.loader.showAndPreventTabbing(LOADER_MESSAGES.paymentSubmission);

			controller.paymentModule.submitForm();
			return true;
		}

		// something wrong happened, display the loader while the iframe is reloaded, it will be removed later at loadZuoraIframe(...) method
		controller.loader.showOnElement(
			controller.paymentType.$paymentType,
			LOADER_MESSAGES.paymentMethodConfig
		);

		// remove overlay to allow user interaction
		controller.loader.hide();
		let shownMessage = Messages.PAYMENT_ERROR;
		if (event.detail) {
			// try to find a mapped message otherwise show default payment submit error message
			const mappedMessage = controller.messages.mapZuoraErrorMessageDetail(event.detail);
			// if mapZuoraErrorMessageDetail can't find any mapped error message will return false
			shownMessage = mappedMessage ? mappedMessage : Messages.PAYMENT_ERROR;
		}
		controller.messages.show(shownMessage);
		controller.messages.focus(shownMessage);
		await loadZuoraIframe(controller);

		// enabling submit button
		controller.submitButton.enable();
	});
}

async function submitPayment(event, controller, submitTrackAction = 'payment-submit') {
	const paymentType = controller.paymentType.getSelected();
	if (paymentType === PaymentType.ZUORAPAYMENTLINK) {
		// Zuora Payment Link tracking
		controller.trackingContext.paymentType = PaymentType.CREDITCARD;
		controller.trackingContext.isDeferredPayment = true;
	}
	controller.trackAction(submitTrackAction);

	controller.messages.hide(Messages.PAYMENT_FAILURE_APPLE_PAY);

	// Allow the form to be submitted if there is a payment method ID.
	if (!controller.paymentModule.hasPaymentMethodId()) {
		const paymentTerm = controller.paymentTerm.getSelected();

		// do not trigger the overlay for credit card, the iframe may require user interaction
		if (paymentType !== PaymentType.CREDITCARD) {
			controller.loader.showAndPreventTabbing(LOADER_MESSAGES.paymentSubmission);
		}

		switch (paymentType) {
			case PaymentType.APPLEPAY:
				event.preventDefault();
				await submitApplePay(controller, paymentTerm);
				break;
			case PaymentType.CREDITCARD:
				event.preventDefault();
				// disable user interactions while user interact with the iframe
				disableUserInteractions();
				// make sure the user will have the iframe in the viewport
				document.getElementById(ZUORA_IFRAME_ID).scrollIntoView();

				await submitCreditcard(controller, paymentType, true);
				break;
			case PaymentType.DIRECTDEBIT:
				event.preventDefault();
				await submitDirectDebit(controller, paymentType);
				break;
			case PaymentType.PAYPAL:
				// Submit the form
				break;
			case PaymentType.ZUORAPAYMENTLINK:
				// Submit the form
				break;
			default:
				event.preventDefault();
				controller.loader.hide();
				controller.submitButton.enable();
				controller.paymentType.displayError();
				if (controller.flags?.get('enableZuoraPaymentLinkOnBuyFlow')) {
					controller.zuoraPaymentLinkSubmitButton.enable();
				}
		}
	}
}

/**
 * disable all actionable fields to protect the payment iframe
 */
function disableUserInteractions() {
	setUserInteractions('disabled');
}

/**
 * restore form elements (required before final submission of the form)
 */
function enableUserInteractions() {
	setUserInteractions('enabled');
}

/**
 * toggles the `disabled` status of the forms elements
 * @param status string:'enabled' | 'disabled'
 */
function setUserInteractions(status) {
	const isDisabled = !(status === 'enabled');
	const paymentTerms = document.getElementsByName('paymentTerm') || [];
	const paymentTypes = document.getElementsByName('paymentType') || [];
	const billingCountry = document.getElementById('billingCountry') || {};
	const termsAcceptance = document.getElementById('termsAcceptance') || {};
	paymentTerms.forEach((term) => (term.disabled = isDisabled));
	paymentTypes.forEach((type) => (type.disabled = isDisabled));
	billingCountry.disabled = isDisabled;
	termsAcceptance.disabled = isDisabled;
}

function trackBillingCountry(controller, category, event) {
	const elementName = controller.billingCountry.$billingCountry.getAttribute('name');
	controller.trackAction(
		'click',
		{
			url: controller.window.location.href,
			domPathTokens: [
				{
					['data-trackable']: `${event} ${elementName}`,
					value: controller.billingCountry.getSelected(),
				},
			],
		},
		category
	);
}

module.exports = {
	ZUORA_IFRAME_ID,
	getBaseControllerProperties,
	initialiseOnLoad,
	submitDirectDebit,
	submitApplePay,
	submitCreditcard,
	updatePaymentTerm,
	updatePaymentType,
	submitPayment,
	trackBillingCountry,
};
